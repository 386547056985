import React from "react"
import Helmet from "react-helmet"
import Uninstalled from "src/components/uninstalled"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UninstalledPage() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}><title>Uninstalled</title></Helmet>
    <Uninstalled></Uninstalled>
    </HomepageLayout>
  )
}
